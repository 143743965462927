import * as React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import HeaderCareer from "../../components/headers/header-career";
import NumberedCards from "../../components/homepage/numbered-cards";
import Layout from "../../components/layout/layout";
import Seo from "../../components/seo/seo";
import BgImage from "../../assets/images/careers/background.png";
import { graphql } from "gatsby";
import TextTwoCols from "../../components/global/text-two-cols";
import Perks from "../../components/careers/perks";
import Description from "../../components/careers/description";
import CtaCarrers from "../../components/careers/cta";
import { useLocation } from "@reach/router";
import { useEffect } from "react";
import { navigate } from "gatsby-link";

const Carrers = () => {
  const { t } = useTranslation();

  const uselocation = useLocation();

  const { pathname } = uselocation;

  let splitLocation = pathname.split("/")[1];
  useEffect(() => {
    if (splitLocation === "de") {
      navigate(`/careers`);
    }
  }, []);

  const headerContent = {
    imageUrl: BgImage,
    title: `${t("careers.openPositions.uiuxDesigner.position")}`,
    location: "Novi Sad / Beograd",
    numberOfPositions: "1",
  };

  const textTwoColContent = {
    title: `${t("aboutUs.about.headlineSecond")}`,
    textLeftFirst: `${t("aboutUs.about.firstText")}`,
    textLeftSecond: `${t("aboutUs.about.secondText")}`,
    textRightFirst: `${t("aboutUs.about.fourthText")}`,
    textRightSecond: `${t("aboutUs.about.fifthText")}`,
  };

  const descriptionContent = {
    title: `${t("careers.openPositions.uiuxDesigner.position")}`,
    duties: [
      `${t("careers.openPositions.uiuxDesigner.duties.1")}`,
      `${t("careers.openPositions.uiuxDesigner.duties.2")}`,
      `${t("careers.openPositions.uiuxDesigner.duties.3")}`,
      `${t("careers.openPositions.uiuxDesigner.duties.4")}`,
      `${t("careers.openPositions.uiuxDesigner.duties.5")}`,
      `${t("careers.openPositions.uiuxDesigner.duties.6")}`,
      `${t("careers.openPositions.uiuxDesigner.duties.7")}`,
    ],
    requirements: [
      `${t("careers.openPositions.uiuxDesigner.requirements.1")}`,
      `${t("careers.openPositions.uiuxDesigner.requirements.2")}`,
      `${t("careers.openPositions.uiuxDesigner.requirements.3")}`,
      `${t("careers.openPositions.uiuxDesigner.requirements.4")}`,
      `${t("careers.openPositions.uiuxDesigner.requirements.5")}`,
      `${t("careers.openPositions.uiuxDesigner.requirements.6")}`,
      `${t("careers.openPositions.uiuxDesigner.requirements.7")}`,
    ],
    plus: [
      `${t("careers.openPositions.uiuxDesigner.plus.1")}`,
      `${t("careers.openPositions.uiuxDesigner.plus.2")}`,
      `${t("careers.openPositions.uiuxDesigner.plus.3")}`,
    ],
    benefits: [
      `${t("careers.openPositions.benefitsList.2")}`,
      `${t("careers.openPositions.benefitsList.5")}`,
      `${t("careers.openPositions.benefitsList.6")}`,
      `${t("careers.openPositions.benefitsList.7")}`,
      `${t("careers.openPositions.benefitsList.9")}`,
      `${t("careers.openPositions.benefitsList.10")}`,
      `${t("careers.openPositions.benefitsList.13")}`,
      `${t("careers.openPositions.benefitsList.14")}`,
    ],
  };

  const numberedCardsContent = {
    title: `${t("careers.numberedCards.headline")}`,
    cards: [
      {
        title: `${t("careers.numberedCards.cards.first.title")}`,
        text: `${t("careers.numberedCards.cards.first.text")}`,
      },
      {
        title: `${t("careers.numberedCards.cards.second.title")}`,
        text: `${t("careers.numberedCards.cards.second.text")}`,
      },
      {
        title: `${t("careers.numberedCards.cards.third.title")}`,
        text: `${t("careers.numberedCards.cards.third.text")}`,
      },
      {
        title: `${t("careers.numberedCards.cards.fourth.title")}`,
        text: `${t("careers.numberedCards.cards.fourth.text")}`,
      },
    ],
  };

  return (
    <Layout>
      <Seo title={t("careers.openPositions.uiuxDesigner.position")} />
      <HeaderCareer headerContent={headerContent} />
      <TextTwoCols textTwoColContent={textTwoColContent} />
      <Description descriptionContent={descriptionContent} />
      <CtaCarrers />
      <Perks />
      <NumberedCards
        numberedCardsContent={numberedCardsContent}
        cssModifer="-jobProcess"
      />
    </Layout>
  );
};

export default Carrers;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
